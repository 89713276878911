import { useState } from "react"

export default function Forms() {
    const [showForm, setShowForm] = useState(true)
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [message, setMessage] = useState("")
    const KEY = "2bc266f9-2910-47df-8181-52750bd31608";

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = {
            name, email, message, access_key: KEY
        }

        try {
            const response = await fetch("https://api.web3forms.com/submit", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json"
                },
                body: JSON.stringify(formData)
            })
            if (!response.ok) {
                console.log(response)
                throw new Error(`Failed to send data: ${response.status} ${response.statusText}`)
            }
            setShowForm(false)
        } catch (error) {
            console.log("!! Error fetching data", error.message);
        }

    }

    const handleSetName = (e) => {
        setName(e.target.value)
    }

    const handleSetEmail = (e) => {
        setEmail(e.target.value)
    }

    const handleSetMessage = (e) => {
        setMessage(e.target.value)
    }
    const newForm =
        <form onSubmit={handleSubmit}>
            <input type='hidden' name="access_key" value={KEY} />
            <label htmlFor="name">Name:</label>
            <input
                type="text"
                id="name"
                name="name"
                value={name}
                onChange={handleSetName}
                required
            />

            <label htmlFor="email">Email:</label>
            <input
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={handleSetEmail}
                required
            />

            <label htmlFor="message">Message:</label>
            <textarea
                id="message"
                name="message"
                required
                onChange={handleSetMessage}
                value={message}
            ></textarea>
            <button type="submit">Submit Form</button>
        </form>

    return (<div className="form_cont">
        <h2>Drop a line:</h2>
        {
            (showForm === true) ? newForm : (<p>Message Sent!</p>)
        }
    </div>
    )
}