import * as React from 'react';

export default function Intro() {
    return (
        <>
            <div className="cont">
                <h1>Front End Web Developer</h1>
                <p>
                    I'm Kimball, a Front-End Developer based in New York City. My technical expertise revolves around e-commerce, where I use HTML, CSS, and JavaScript to showcase data, improve user interaction, and craft exceptional user experiences to inform and boost conversions.

                    <br />
                    Currently, I'm diving into React.js and Vue.js, and I'm excited about crafting for both front-end systems.
                </p>
            </div>
        </>
    )
} 