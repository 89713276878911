import { useState, useEffect } from "react";

function useApi(key) {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null)

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await fetch("//www.kbledeveloper.com/data/data.json");
            if (!response.ok) {
                throw new Error(`Failed to fetch data: ${response.status} ${response.statusText}`)
            }
            const jsonData = await response.json();
            setData(jsonData[key] || []);
            setLoading(false)
        } catch (error) {
            console.log("!! Error fetching data", error.message);
            setError(error)
            setLoading(false)
        }
    }

    return [data, loading, error]
}
export default useApi;