import useApi from "./useApi.js"

export default function Skills() {
    const [data, loading, error] = useApi("technical skills")
    return (
        <>
            <div className="cont">
                <h2>Technical Skills</h2>
                {
                    loading ? (<p>Loading...</p>) : error ? (
                        <p>Error: {error}</p>
                    ) : (
                        <ul>
                            {
                                data.map(
                                    (skill, index) => <li key={index}>{skill}</li>
                                )
                            }
                        </ul>
                    )
                }
            </div>
        </>
    )
}