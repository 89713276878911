import useApi from "./useApi.js"

export default function Experience() {
    const [data, loading, error] = useApi("experience");
    return (
        <>
            <div className="cont">
                <h2>
                    Experience
                </h2>
                {
                    loading ? "Loading" : error ? (<p>Error: {Error}</p>) : (
                        <ul>
                            {data.map((item, index) => <li key={index}>{item}</li>)}
                        </ul>
                    )
                }

            </div>
        </>
    )
};
