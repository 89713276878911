import { useState } from "react"

import './App.css';
import './global.scss';
import Intro from './Intro.js';
import Experience from './Experience.js';
import Skills from './Skills.js';
import MoreToCome from './MoreToCome.js';
import Form from './Form.js';

import Lottie from "lottie-react";
import lottie from "./lottie.json"


// 2bc266f9-2910-47df-8181-52750bd31608



function App() {

  const [message, setMessage] = useState("More to come!");

  function handleResumeClick() {
    setMessage("Thanks for visiting!");
  }

  return (
    <div className="App">
      <div className="App-header">
        <Lottie animationData={lottie} loop={true} />
        <Intro />
        <Experience />
        <Skills />
        <MoreToCome message={message} onClick={handleResumeClick} />
        <Form />
      </div>
    </div>
  );
}

export default App;
