export default function MoreToCome({ message, onClick }) {
    return (
        <>
            <div className="cont">
                <h2>{message}</h2>
                <p>More site updates to come.
                    <br />
                    <br />
                    For more info, please check out my <a href='http://kbledeveloper.com/downloads/kimball-resume-2023.pdf' onClick={onClick} target="_blank" rel="noreferrer">resume</a> below.</p>
                <br />
            </div>
        </>
    )
}